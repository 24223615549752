<template>
    <div class="update-password">
        <table>
            <tr height="50">
                <td>Email:</td>
                <td><input type="email" v-model="email"></td>
            </tr>
            <tr height="50">
                <td>Old Password:</td>
                <td><input type="password" v-model="oldPassword"></td>
            </tr>
            <tr height="50">
                <td>New Password:</td>
                <td><input type="password" v-model="newPassword"></td>
            </tr>
            <tr height="50">
                <td>Repeat New Password:</td>
                <td><input type="password" v-model="rPassword"></td>
            </tr>
            <tr height="50">
                <td colspan="2" style="text-align:center;"><button @click="updatePassword" >Update Password</button></td>
            </tr>
        </table>
    </div>
</template>

<script>
import gql from "graphql-tag"
export default {
    data(){
        return{
            email:'',
            oldPassword:'',
            newPassword:'',
            rPassword:''
        }
    },
    methods:{
        updatePassword(){
            if(this.newPassword==this.rPassword){
                this.$apollo.mutate({
                    mutation:gql`
                    mutation updatePassword($email: String! $oldPassword: String! $newPassword: String! ){
                        updatePassword(email:$email, oldPassword:$oldPassword, newPassword:$newPassword){
                            errors{
                                message
                            }
                            message

                            
                        }
                    }
                    
                    `,
                    variables:{
                        email:this.email,
                        oldPassword:this.oldPassword,
                        newPassword:this.newPassword
                    }
                })
            }else{
                alert('parolalar uyuşmuyor')
            }
        }
    }

}
</script>

<style scoped>
.update-password{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 15%;
}
td{
    padding: 20px 0;
}
</style>